import React from 'react';
import styled from 'styled-components';
import media from '../utils/media';

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.tabDown`
    margin-left: 1px;
  `}

  ${media.desk`
    flex-direction: column;
    margin-bottom: 1px;
  `}
`;

const Tag = styled.div`
  padding: 4px 18px;
  background: rgba(255,255,255,0.95);
  color: ${props => props.theme.blogTagDisabledTextColor};
  border: 1px solid ${props => props.theme.blogTagDisabledColor};
  // filter: ${props => props.theme.buttonShadowFilter};

  ${media.tabDown`
    margin-left: -1px;
  `}

  ${media.desk`
    margin-bottom: -1px;
  `}
`;

const BlogTags = ({ tags }) => (
  <Column>
  {
    tags.map(tag => <Tag key={tag}><small>{tag}</small></Tag>)
  }
  </Column>
);

export default BlogTags;